import React from 'react';
import { Layout, SEO } from 'components';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to the Gatsby &amp; Shopify starter.</p>
    <Link to="/products/dream-body-heather">Link 1</Link>
    <Link to="/products/metsolan-lastenvaatemallisto">Link 2</Link>
  </Layout>
);

export default IndexPage;
